// extracted by mini-css-extract-plugin
export var footer = "Footer-module--footer--3f3e5";
export var footerLogo = "Footer-module--footerLogo--b7ead";
export var footerLogos = "Footer-module--footerLogos--36c4c";
export var footerNavi = "Footer-module--footerNavi--72d04";
export var footerNaviHeading = "Footer-module--footerNaviHeading--b10bf";
export var footerSocial = "Footer-module--footerSocial--59ce7";
export var footerWrapper = "Footer-module--footerWrapper--b6707";
export var grid = "Footer-module--grid--6b322";
export var mdFlex = "Footer-module--mdFlex--2cbe5";
export var mdJustifyBetween = "Footer-module--mdJustifyBetween--deec5";