// src/components/HistorySection.tsx

import React from 'react';
import { Link } from 'gatsby';
import ScrollAnimation from '../ScrollAnimation/ScrollAnimation';
import * as styles from './HistorySection.module.css';

type ColorType = 'primary' | 'secondary';

interface HistoryItem {
  id: number;
  postDate: string;
  content: string;
  url?: string;
}

interface HistorySectionProps {
  title: string;
  subtitle?: string;
  historyItems: HistoryItem[];
  sectionId: string;
  colorType: ColorType;
}

const HistorySection: React.FC<HistorySectionProps> = ({
  title = '沿革',
  subtitle,
  historyItems,
  sectionId,
  colorType,
}) => {
  let SectionClass = styles.sectionPrimary; // デフォルトのスタイル
  if (colorType === 'primary') {
    SectionClass = styles.sectionPrimary;
  } else if (colorType === 'secondary') {
    SectionClass = styles.sectionSecondary;
  }
  return (
    <ScrollAnimation>
      <section className={SectionClass} id={sectionId}>
        <div className={styles.historySectionInner}>
          <h2 className={styles.head}>
            {title && <span className={styles.headText}>{title}</span>}
            {subtitle && <span className={styles.headSubtext}>{subtitle}</span>}
          </h2>
          <div>
            {historyItems.map((historyItem: HistoryItem) => (
              <div key={historyItem.id} className={styles.historyItem}>
                <h3 className={styles.historyPostDate}>
                  {historyItem.postDate}
                </h3>
                {historyItem.url ? (
                  <Link to={historyItem.url} className={styles.historyContent}>
                    {historyItem.content}
                  </Link>
                ) : (
                  <p className={styles.historyContent}>{historyItem.content}</p>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>
    </ScrollAnimation>
  );
};

export default HistorySection;
