// extracted by mini-css-extract-plugin
export var HistorySection = "HistorySection-module--HistorySection--295dc";
export var head = "HistorySection-module--head--7436f";
export var headSubtext = "HistorySection-module--headSubtext--e2eb2";
export var headText = "HistorySection-module--headText--b094b";
export var header = "HistorySection-module--header--ec333";
export var historyContent = "HistorySection-module--historyContent--27f68";
export var historyItem = "HistorySection-module--historyItem--4273a";
export var historyPostDate = "HistorySection-module--historyPostDate--11323";
export var historySectionInner = "HistorySection-module--historySectionInner--31f0f";
export var sectionPrimary = "HistorySection-module--sectionPrimary--8d5b2";
export var sectionSecondary = "HistorySection-module--sectionSecondary--2ebf8";