import * as React from 'react';
import Seo from '../components/seo';
import Layout from '../components/layout';
import HistorySection from '../components/HistorySection/HistorySection';

const WazaiHistorytPage = ({ data, location }) => {
  return (
    <div>
      <Layout>
        <HistorySection
          sectionId="section1"
          title="講演等の活動実績"
          historyItems={[
            {
              id: 1,
              postDate: '2017年2月',
              content: '北九州市保健福祉局　職員学習会　講師',
            },
            {
              id: 2,
              postDate: '2018年1月',
              content: '九州労災病院　地域研修会　講師',
            },
            {
              id: 3,
              postDate: '2018年3月',
              content: '雑誌「作業療法ジャーナル」52巻6号にて執筆',
            },
            {
              id: 4,
              postDate: '2018年11月',
              content: '公益社団法人福岡県作業療法協会　地域分野研修会　講師',
            },
            {
              id: 5,
              postDate: '2019年3月',
              content: '雑誌「月刊デイ」にて執筆',
            },
            {
              id: 6,
              postDate: '2019年6月',
              content: '雑誌「通所サービス＆マネジメント」にて執筆',
            },
            {
              id: 7,
              postDate: '2019年6月',
              content: '下関市立大学 「中小企業論」 ゲスト講師',
            },
            {
              id: 8,
              postDate: '2019年6月',
              content:
                '公益社団法人福岡県作業療法協会　北九州ブロック研修会　講師',
            },
            {
              id: 9,
              postDate: '2020年3月',
              content:
                '公益社団法人福岡県作業療法協会　機関紙「ジャーナル作業療法福岡」にて執筆',
            },
            {
              id: 10,
              postDate: '2022年9月',
              content: '雑誌「作業療法ジャーナル」56巻11号にて執筆',
            },
          ]}
        />
        <HistorySection
          sectionId="section2"
          title="表彰・受賞歴"
          historyItems={[
            {
              id: 1,
              postDate: '2017年2月',
              content:
                'リノベーションオブザイヤー2017　＜無差別級部門＞ 最優秀作品賞',
            },
            {
              id: 2,
              postDate: '2019年6月',
              content: '第５回福岡県木造・木質化建築賞、奨励賞',
            },
            {
              id: 3,
              postDate: '2020年2月',
              content:
                '令和元年度　福岡県ひとり暮らし高齢者等見守り活動　知事表彰',
            },
          ]}
        />
        <HistorySection
          sectionId="section3"
          title="メディア掲載歴"
          historyItems={[
            {
              id: 1,
              postDate: '2015年7月',
              content: '西日本新聞 コラム',
            },
            {
              id: 2,
              postDate: '2016年',
              content: '北九州市保健福祉局「保健福祉レポート2016」',
            },
            {
              id: 3,
              postDate: '2016年3月',
              content: 'FBS「めんたいワイド」※団らん処 和菜屋',
            },
            {
              id: 4,
              postDate: '2016年3月',
              content: '毎日新聞　コラム',
            },
            {
              id: 5,
              postDate: '2016年3月',
              content: 'TNCテレビ「ももち浜ストア」　※団らん処 和菜屋',
            },
            {
              id: 6,
              postDate: '2017年9月',
              content: 'FBSテレビ「めんたいプラス」',
            },
            {
              id: 7,
              postDate: '2017年10月',
              content: '毎日新聞　コラム',
            },
            {
              id: 8,
              postDate: '2017年10月',
              content: '西日本新聞　てくてく北九州',
            },
            {
              id: 9,
              postDate: '2017年11月',
              content: 'RKBテレビ「ニュースプラス」',
            },
            {
              id: 10,
              postDate: '2018年1月',
              content: '読売新聞　※デイサービス 和才屋',
            },
            {
              id: 11,
              postDate: '2018年1月',
              content: 'UR都市機構の情報誌「UR PRERSS」　※団らん処 和菜屋',
            },
            {
              id: 12,
              postDate: '2018年5月',
              content: 'NHKテレビ「ニュースブリッジ北九州」',
            },
            {
              id: 13,
              postDate: '2018年3月',
              content: 'KBCテレビ「アサデス。」',
            },
            {
              id: 14,
              postDate: '2019年3月',
              content:
                '文化放送ラジオ「レコメン！」＆ホームページ「URくらしのカレッジ」',
            },
            {
              id: 15,
              postDate: '2021年8月',
              content: 'UR都市機構広報誌「あおぞら8月号」掲載',
            },
          ]}
        />
        <HistorySection
          sectionId="section4"
          title="その他"
          historyItems={[
            {
              id: 1,
              postDate: '2015年9月',
              content: '日田市長　団らん処 和菜屋　視察',
            },
            {
              id: 2,
              postDate: '2017年9月',
              content: '北九州市長　団らん処 和菜屋・デイサービス和才屋　視察',
            },
            {
              id: 3,
              postDate: '2018年5月',
              content: '北九州市長　金田団地でのイベント「春の集い」　視察',
            },
            {
              id: 4,
              postDate: '2019年1月',
              content: 'UR都市機構本社　理事（元長崎県副知事） 視察',
            },
          ]}
        />
      </Layout>
    </div>
  );
};

export default WazaiHistorytPage;

export const Head = () => (
  <Seo
    title="和菜屋の歴史"
    description="リノベーションオブザイヤー2017　＜無差別級部門＞ 最優秀作品賞/第５回福岡県木造・木質化建築賞、奨励賞/令和元年度　福岡県ひとり暮らし高齢者等見守り活動　知事表彰"
  />
);
