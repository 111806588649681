// Footer.tsx
import React from 'react';
import * as styles from './Footer.module.css';
import { Link } from 'gatsby';

// 型定義
interface Category {
  name: string;
  link: string;
}

interface Item {
  categoriesname: string;
  categories: Category[];
}

interface FooterProps {
  items: Item[];
  logo: string;
  logoAltText: string;
  copyrightText: string;
  logoLinkText: string;
  facebookUrl: string;
  instagramUrl: string;
  xUrl: string;
}

const Footer: React.FC<FooterProps> = ({
  items,
  logo,
  logoAltText,
  copyrightText,
  logoLinkText,
  facebookUrl,
  instagramUrl,
  xUrl,
}) => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerWrapper}>
        <div className={styles.mdFlex + ' ' + styles.mdJustifyBetween}>
          <div className={styles.footerLogos}>
            <a href={logoLinkText} className={styles.footerLogo}>
              <img src={logo} width="140" height="20" alt={logoAltText} />
            </a>
            <Link to="/dayservice/">
              <img
                src="/wazai/logo2.png"
                alt="デイサービス"
                width="140"
                height="20"
              />
            </Link>
          </div>
          <div className={styles.grid}>
            {items.map((item) => (
              <div key={item.categoriesname}>
                <p className={styles.footerNaviHeading}>
                  {item.categoriesname}
                </p>
                <ul className={styles.footerNavi}>
                  {item.categories.map((category) => (
                    <li key={category.name}>
                      <a href={category.link}>{category.name}</a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
      <hr />
      <div className={styles.footerWrapper}>
        <p>
          © {new Date().getFullYear()}{' '}
          <a href={logoLinkText}>{copyrightText}</a>
          All Rights Reserved.
        </p>
        <div className={styles.footerSocial}>
          {facebookUrl ? (
            <a href={facebookUrl}>
              <svg fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path
                  fill-rule="evenodd"
                  d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>
          ) : null}
          {instagramUrl ? (
            <a href={instagramUrl}>
              <svg fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path
                  fill-rule="evenodd"
                  d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>
          ) : null}
          {xUrl ? (
            <a href={xUrl}>
              <svg
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
                width={18}
                height={18}
                transform="translate(0, -3)"
              >
                <g>
                  <path d="M14.258 10.152L23.176 0h-2.113l-7.747 8.813L7.133 0H0l9.352 13.328L0 23.973h2.113l8.176-9.309 6.531 9.309h7.133zm-2.895 3.293l-.949-1.328L2.875 1.56h3.246l6.086 8.523.945 1.328 7.91 11.078h-3.246zm0 0"></path>
                </g>
              </svg>
            </a>
          ) : null}
        </div>
      </div>
    </footer>
  );
};

export default Footer;

// 入力例
// <Footer
//   items={[
//     {
//       categoriesname: "Category 1",
//       categories: [
//         { name: "Link 1", link: "/link1" },
//         { name: "Link 2", link: "/link2" }
//       ]
//     },
//     {
//       categoriesname: "Category 2",
//       categories: [
//         { name: "Link 3", link: "/link3" },
//         { name: "Link 4", link: "/link4" }
//       ]
//     }
//   ]}
//   logo="/path/to/logo.png"
//   logoAltText="Logo"
//   copyrightText="Your Company Name"
//   logoLinkText="/"
// />
